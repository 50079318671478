import React, { FC } from 'react'
import dynamic from 'next/dynamic'

const BlogPostList = dynamic(() => import('../components/BlogPosts'))

interface Props {
  title: string
  action: string
  alt?: string
  data: Array<PressGlobal.BlogPostItem>
}

const BlogPostContainer: FC<Props> = ({ title, action, alt, data }) => (
  <BlogPostList title={title} action={action} alt={alt || action} posts={data} />
)

export default BlogPostContainer
